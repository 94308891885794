import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { ButtonBase, IconButtonBase, } from '../button-base/index.js';
import { buttonTertiaryStyles, } from './button-tertiary.css.js';
export const ButtonTertiary = forwardRef(({ color, opaque, ...buttonBaseProps }, ref) => {
    return (_jsx(ButtonBase, { ref: ref, theme: buttonTertiaryStyles({
            color: color || 'default',
            opaque,
        }), ...buttonBaseProps }));
});
export const IconButtonTertiary = forwardRef(({ color, opaque, ...buttonBaseProps }, ref) => {
    return (_jsx(IconButtonBase, { ref: ref, theme: buttonTertiaryStyles({
            color: color || 'default',
            opaque,
        }), ...buttonBaseProps }));
});
