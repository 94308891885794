/**
 * @fileoverview Paths
 * This provides utilities for working with file paths.
 * It may have things that could be from Node.js's `path` module but that's b/c it's for browser usage.
 */

/**
 * Gets the file name from a path string, similar to Node's path.parse().name
 */
export const getFileNameFromPath = (path: string): string => {
  try {
    if (!path) return '';
    // Split on forward or back slashes
    const parts = path.split(/[/\\]/);
    // Get last part
    const fileName = parts[parts.length - 1];
    // Remove extension if exists
    const parts2 = fileName.split('.');
    // If only one part, return it
    if (parts2.length === 1) return parts2[0];
    // If multiple parts, return all except last
    return parts2.slice(0, -1).join('.');
  } catch (_) {
    return path;
  }
};
