'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, createContext, useContext, useMemo, forwardRef, isValidElement, cloneElement, useEffect, useRef, useCallback, } from 'react';
import { isFragment } from 'react-is';
import { useFloating, autoUpdate, offset, flip, shift, useHover, useFocus, useDismiss, useRole, useInteractions, FloatingPortal, inline, } from '@floating-ui/react';
import { mergeRefs } from 'react-merge-refs';
import { isObject } from '@knapsack/utils';
import { tooltipContentStyles } from './tooltip.css.js';
import { TextBody } from '../text/index.js';
function useTooltip({ initialOpen = false, placement = 'top', open: controlledOpen, onOpenChange: setControlledOpen, } = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    const setOpenRef = useRef(setOpen);
    setOpenRef.current = setOpen;
    const data = useFloating({
        placement,
        open,
        onOpenChange: (o) => setOpenRef.current(o),
        whileElementsMounted: autoUpdate,
        strategy: 'fixed',
        middleware: [inline(), offset(6), flip(), shift({ padding: 5 })],
    });
    const { context, update } = data;
    const memoizedUpdate = useCallback(() => {
        update();
    }, [update]);
    useEffect(() => {
        memoizedUpdate();
    }, [memoizedUpdate]);
    const hover = useHover(context, {
        move: false,
        enabled: !controlledOpen,
        delay: {
            close: 0,
            open: 250,
        },
    });
    const focus = useFocus(context, {
        enabled: true,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });
    const interactions = useInteractions([hover, focus, dismiss, role]);
    return useMemo(() => ({
        open,
        setOpen,
        ...interactions,
        ...data,
    }), [open, setOpen, interactions, data]);
}
const TooltipContext = createContext(null);
const useTooltipState = () => {
    const context = useContext(TooltipContext);
    if (context == null) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }
    return context;
};
function TooltipBase({ children, ...options }) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const tooltip = useTooltip(options);
    return (_jsx(TooltipContext.Provider, { value: tooltip, children: children }));
}
const TooltipTrigger = forwardRef(({ children, autoWrap = false, fullWidth = false, ...props }, propRef) => {
    const state = useTooltipState();
    const childrenRef = children?.ref;
    const ref = useMemo(() => mergeRefs([state.refs.setReference, propRef, childrenRef].filter(Boolean)), [state.refs.setReference, propRef, childrenRef]);
    // `autoWrap` allows the user to pass any child element as the Tooltip anchor,
    // otherwise, we need to wrap / render a tooltip anchor on your behalf (which can affect
    // style behavior)
    if (!autoWrap && !isFragment(children) && isValidElement(children)) {
        return cloneElement(children, state.getReferenceProps({
            ref,
            ...props,
            ...(isObject(children?.props) ? children.props : {}),
        }));
    }
    return (_jsx("div", { ref: ref, style: {
            display: fullWidth ? 'flex' : 'inline-flex',
            border: 0,
            background: 'transparent',
            padding: 0,
        }, ...state.getReferenceProps(props), children: children }));
});
const TooltipContent = forwardRef((props, propRef) => {
    const state = useTooltipState();
    const ref = useMemo(() => mergeRefs([state.refs.setFloating, propRef]), [state.refs.setFloating, propRef]);
    if (!state.open)
        return null;
    return (_jsx(FloatingPortal, { children: _jsx("div", { ref: ref, className: tooltipContentStyles({ position: state.strategy }), style: {
                top: state.y ?? 0,
                left: state.x ?? 0,
                transition: 'opacity 0.1s ease',
            }, ...state.getFloatingProps(props), children: props.children }) }));
});
export const Tooltip = (props) => {
    const { tooltipContent, autoWrap, placement, open, initialOpen, onOpenChange, children, label, fullWidthTrigger = false, } = props;
    return (_jsxs(TooltipBase, { open: open, initialOpen: initialOpen, onOpenChange: onOpenChange, placement: placement, children: [_jsx(TooltipTrigger, { autoWrap: autoWrap, fullWidth: fullWidthTrigger, children: children }), _jsx(TooltipContent, { label: label, children: React.isValidElement(tooltipContent) ? (tooltipContent) : (_jsx(TextBody, { tag: "span", size: "small", display: "block", spacing: "none", children: tooltipContent })) })] }));
};
