import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import clsx from 'clsx';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import { camelCase, isPromise } from '@knapsack/utils';
import { useStateFromProp } from '@knapsack/hooks';
import { Icon } from '../../icon/icon.js';
import { TextBody } from '../../text/index.js';
import { buttonBase, buttonLabelStyles, buttonInnerWrapper, buttonDropdownIndicator, } from './button-base.css.js';
export const ButtonBase = forwardRef((props, ref) => {
    const { ariaAttributes, className, disabled, hasDropdown, form, fullWidth, icon, iconColor, loading: loadingProp = false, label, onMouseEnter, onMouseLeave, onTrigger: onTriggerProp, rightIcon, rounded = true, size = 'medium', testId, theme, type = 'button', zIndex, inheritTextStyles = false, ...otherProps } = props;
    const iconSize = size === 'small' || size === 'xsmall' ? 'xsmall' : 'small';
    const textSize = size === 'xsmall' ? 'small' : 'medium';
    const [loading, setLoading] = useStateFromProp(loadingProp);
    const isUsingKeyboard = useUsingKeyboard(false);
    const onTrigger = (...args) => {
        const result = onTriggerProp(...args);
        if (isPromise(result)) {
            setLoading(true);
            result.finally(() => {
                setLoading(false);
            });
        }
        return result;
    };
    return (_jsx("button", { ...ariaAttributes, className: clsx(className, {
            'is-using-keyboard': isUsingKeyboard,
        }, [buttonBase({ size, fullWidth, rounded }), theme]), "data-testid": testId || camelCase(label), disabled: disabled || loading, form: form, 
        // eslint-disable-next-line react/button-has-type
        type: type, onClick: (event) => onTrigger({
            type: 'click',
            event,
        }), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                onTrigger({
                    type: 'key',
                    key: event.key,
                    event,
                });
            }
        }, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, ref: ref, style: { zIndex }, ...otherProps, children: _jsxs("span", { className: buttonInnerWrapper, children: [(icon || loading) &&
                    (iconColor ? (_jsx("span", { style: { color: iconColor }, children: _jsx(Icon, { spinning: loading || icon === 'loading', symbol: loading ? 'loading' : icon, size: iconSize }) })) : (_jsx(Icon, { spinning: loading || icon === 'loading', symbol: loading ? 'loading' : icon, size: iconSize }))), inheritTextStyles ? (_jsx("span", { className: buttonLabelStyles, children: label })) : (_jsx(TextBody, { className: buttonLabelStyles, weight: "medium", size: textSize, tag: "span", align: "center", children: label })), rightIcon && _jsx(Icon, { symbol: rightIcon, size: iconSize }), hasDropdown && (_jsx(Icon, { className: buttonDropdownIndicator, symbol: "select", size: size === 'large' ? 'small' : 'xsmall' }))] }) }));
});
