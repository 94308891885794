import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import clsx from 'clsx';
import { camelCase } from '@knapsack/utils';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import { Icon } from '../../icon/icon.js';
import { Tooltip } from '../../tooltip/index.js';
import { iconButtonBase, } from './icon-button-base.css.js';
import { preventInteraction } from '../../../css-utils.css.js';
export const IconButtonBase = forwardRef((props, ref) => {
    const { ariaAttributes, className, disabled, form, icon, label, loading = false, onMouseEnter, onMouseLeave, onTrigger, size = 'medium', testId, theme, tooltipContent, tooltipPlacement, type = 'button', zIndex, ...otherProps } = props;
    const isUsingKeyboard = useUsingKeyboard(false);
    return (_jsx(Tooltip, { label: label, tooltipContent: tooltipContent || label, placement: tooltipPlacement, children: _jsx("button", { ...ariaAttributes, "aria-label": label, className: clsx(className, {
                'is-using-keyboard': isUsingKeyboard,
            }, [iconButtonBase({ size }), theme]), "data-testid": testId || camelCase(label), disabled: disabled, form: form, 
            // eslint-disable-next-line react/button-has-type
            type: type, onClick: (event) => onTrigger({
                type: 'click',
                event,
            }), onKeyDown: (event) => {
                if (event.key === 'Enter') {
                    onTrigger({
                        type: 'key',
                        key: event.key,
                        event,
                    });
                }
            }, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, ref: ref, style: { zIndex }, ...otherProps, children: _jsx(Icon, { className: preventInteraction, size: size, spinning: loading, symbol: loading ? 'loading' : icon }) }) }));
});
