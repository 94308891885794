import { assignInlineVars } from '@vanilla-extract/dynamic';
export function assignThemeVars(contract, theme) {
    if (!theme)
        return;
    /**
     * For each key in the theme, make sure it is not undefined before
     * assigning it to prevent the default value from being overridden.
     */
    Object.entries(theme).forEach(([key, value]) => {
        if (value === undefined)
            delete theme[key];
    });
    if (Object.keys(theme).length === 0)
        return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return assignInlineVars(contract, theme);
}
