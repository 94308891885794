import { isUrl } from './strings';

export class FetcherError extends Error {
  status: number;
  statusText: string;
  url: string;
  constructor(
    message: string,
    {
      status,
      statusText,
      url,
    }: { status: number; statusText: string; url: string },
  ) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.url = url;
  }
}

export async function fetcher<T = Record<string, unknown>>({
  url,
  query,
  body,
  headers: customHeaders = {},
  signal,
}: {
  url: string | URL;
  query?: Record<string, string | boolean | number>;
  body?: Record<string, any>;
  headers?: RequestInit['headers'];
  signal?: AbortSignal;
}): Promise<T> {
  if (typeof url === 'string' && !isUrl(url)) {
    throw new Error(`Cannot fetch from an invalid url: "${url}"`);
  }
  if (typeof url !== 'string' && !(url instanceof URL)) {
    throw new Error(`Url must be a string or URL instance: "${url}"`);
  }
  const method = body ? 'POST' : 'GET';
  let fetchUrl = '';
  try {
    const u = url instanceof URL ? url : new URL(url);
    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (typeof value !== 'undefined' || value !== null) {
          u.searchParams.set(key, `${value}`);
        }
      });
    }
    fetchUrl = u.toString();
  } catch (error) {
    throw new Error(
      `Could not start fetcher because was not able to add query params to URL successfully. Url: ${url}, Query params: ${JSON.stringify(
        query ?? {},
      )}. ${error instanceof Error ? error.message : ''}`,
    );
  }

  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
  };
  if (body) headers['Content-Type'] = 'application/json';

  const res = await fetch(fetchUrl, {
    method,
    headers: { ...headers, ...customHeaders },
    body: body ? JSON.stringify(body) : undefined,
    signal,
  });

  if (!res.ok) {
    const resp = await res.json();
    if (resp?.error?.message) {
      throw new FetcherError(resp.error.message, {
        status: res.status,
        statusText: res.statusText,
        url: fetchUrl,
      });
    } else if (resp?.message) {
      throw new FetcherError(resp.message, {
        status: res.status,
        statusText: res.statusText,
        url: fetchUrl,
      });
    } else {
      throw new FetcherError(`${res.statusText} ${res.status}`, {
        status: res.status,
        statusText: res.statusText,
        url: fetchUrl,
      });
    }
  }

  if (!res.headers.get('Content-Type')?.includes('json')) {
    const text = await res.text();
    throw new FetcherError(
      `Expected JSON response but received text instead: ${text}`,
      {
        status: res.status,
        statusText: res.statusText,
        url: fetchUrl,
      },
    );
  }

  const data: T = await res.json();
  return data;
}
