export * from './action/index.js';
export * from './branching/index.js';
export * from './brands/index.js';
export * from './indicator/index.js';
export * from './item/index.js';
export * from './layout/index.js';
export * from './state/index.js';
export * from './system/index.js';
export * from './table/index.js';
export * from './text/index.js';
export const IconNamesKebabCaseMap = {
    "add": "Add",
    "back": "Back",
    "chat": "Chat",
    "clear": "Clear",
    "close": "Close",
    "compare": "Compare",
    "copy": "Copy",
    "delete": "Delete",
    "download": "Download",
    "drag": "Drag",
    "duplicate": "Duplicate",
    "edit": "Edit",
    "filter": "Filter",
    "forward": "Forward",
    "minus": "Minus",
    "more": "More",
    "more-vertical": "MoreVertical",
    "move-to": "MoveTo",
    "plus": "Plus",
    "refresh": "Refresh",
    "replace": "Replace",
    "search": "Search",
    "send": "Send",
    "sort-asc": "SortAsc",
    "sort-desc": "SortDesc",
    "toggle-left": "ToggleLeft",
    "toggle-right": "ToggleRight",
    "undo": "Undo",
    "upload": "Upload",
    "branch": "Branch",
    "commit": "Commit",
    "github": "Github",
    "protected": "Protected",
    "publish": "Publish",
    "pull-request": "PullRequest",
    "review": "Review",
    "status-draft": "StatusDraft",
    "status-proposed": "StatusProposed",
    "team-draft": "TeamDraft",
    "abstract": "Abstract",
    "adobe-xd": "AdobeXd",
    "airtable": "Airtable",
    "figma": "Figma",
    "framer": "Framer",
    "google": "Google",
    "lucidchart": "Lucidchart",
    "vimeo": "Vimeo",
    "whimsical": "Whimsical",
    "youtube": "Youtube",
    "ai": "Ai",
    "arrow-down": "ArrowDown",
    "arrow-left": "ArrowLeft",
    "arrow-right": "ArrowRight",
    "arrow-up": "ArrowUp",
    "caret-down": "CaretDown",
    "caret-left": "CaretLeft",
    "caret-right": "CaretRight",
    "caret-up": "CaretUp",
    "checkbox-indeterminate": "CheckboxIndeterminate",
    "checkbox-off": "CheckboxOff",
    "checkbox-on": "CheckboxOn",
    "checked": "Checked",
    "color-picker": "ColorPicker",
    "color-token": "ColorToken",
    "color-value": "ColorValue",
    "hidden": "Hidden",
    "info-circle": "InfoCircle",
    "link": "Link",
    "link-break": "LinkBreak",
    "link-external": "LinkExternal",
    "loading": "Loading",
    "locked": "Locked",
    "menu": "Menu",
    "menu-plus": "MenuPlus",
    "nested": "Nested",
    "question": "Question",
    "question-circle": "QuestionCircle",
    "select": "Select",
    "settings": "Settings",
    "sides": "Sides",
    "unlocked": "Unlocked",
    "visible": "Visible",
    "attachment": "Attachment",
    "book": "Book",
    "bug": "Bug",
    "code": "Code",
    "email": "Email",
    "embed": "Embed",
    "file": "File",
    "folder": "Folder",
    "folder-locked": "FolderLocked",
    "image": "Image",
    "markdown": "Markdown",
    "megaphone": "Megaphone",
    "newspaper": "Newspaper",
    "page": "Page",
    "ruler": "Ruler",
    "table": "Table",
    "tabs": "Tabs",
    "user": "User",
    "user-circle": "UserCircle",
    "video": "Video",
    "layout-header": "LayoutHeader",
    "layout-page": "LayoutPage",
    "state-error": "StateError",
    "state-info": "StateInfo",
    "state-loading": "StateLoading",
    "state-success": "StateSuccess",
    "state-warning": "StateWarning",
    "angular": "Angular",
    "angular-color": "AngularColor",
    "asset-set": "AssetSet",
    "component-demos": "ComponentDemos",
    "component-list": "ComponentList",
    "component-playground": "ComponentPlayground",
    "dashboard": "Dashboard",
    "design-source": "DesignSource",
    "design-source-component": "DesignSourceComponent",
    "design-source-frame": "DesignSourceFrame",
    "design-source-variant": "DesignSourceVariant",
    "design-tokens": "DesignTokens",
    "guidelines": "Guidelines",
    "hbs": "Hbs",
    "hbs-color": "HbsColor",
    "html": "Html",
    "html-color": "HtmlColor",
    "knapsack": "Knapsack",
    "plugin": "Plugin",
    "prototype": "Prototype",
    "react": "React",
    "react-color": "ReactColor",
    "system-overview": "SystemOverview",
    "token-boolean": "TokenBoolean",
    "token-border": "TokenBorder",
    "token-collection": "TokenCollection",
    "token-color": "TokenColor",
    "token-cubic-bezier": "TokenCubicBezier",
    "token-dimension": "TokenDimension",
    "token-duration": "TokenDuration",
    "token-shadow": "TokenShadow",
    "token-transition": "TokenTransition",
    "token-typography": "TokenTypography",
    "vue": "Vue",
    "vue-color": "VueColor",
    "web-components": "WebComponents",
    "web-components-color": "WebComponentsColor",
    "column-plus-after": "ColumnPlusAfter",
    "column-plus-before": "ColumnPlusBefore",
    "row-plus-after": "RowPlusAfter",
    "row-plus-before": "RowPlusBefore",
    "table-column-header": "TableColumnHeader",
    "table-equal-columns": "TableEqualColumns",
    "table-row-header": "TableRowHeader",
    "align-center": "AlignCenter",
    "align-left": "AlignLeft",
    "align-right": "AlignRight",
    "body-text": "BodyText",
    "bold": "Bold",
    "clear-formatting": "ClearFormatting",
    "code-snippet": "CodeSnippet",
    "divider": "Divider",
    "hash": "Hash",
    "heading": "Heading",
    "heading1": "Heading1",
    "heading2": "Heading2",
    "heading3": "Heading3",
    "heading4": "Heading4",
    "heading5": "Heading5",
    "heading6": "Heading6",
    "italic": "Italic",
    "list-ordered": "ListOrdered",
    "list-unordered": "ListUnordered",
    "quote": "Quote",
    "strikethrough": "Strikethrough",
    "text-editor": "TextEditor",
    "underline": "Underline"
};
