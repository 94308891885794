'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import * as Icons from '@knapsack/icons';
import { icon } from './icon.css.js';
import { preventInteraction } from '../../css-utils.css.js';
/**
 * Narrow down a dynamic string to being a defined Icon symbol name
 */
export const isIconId = (maybeIcon) => !!Icons[Icons.IconNamesKebabCaseMap[maybeIcon]];
export const Icon = ({ className, color = 'inherit', size = 'small', spinning = false, symbol: kebabCaseIconName, testId, }) => {
    const CamelCaseIconName = Icons.IconNamesKebabCaseMap[kebabCaseIconName];
    const TheIcon = Icons[CamelCaseIconName];
    if (!TheIcon) {
        throw new Error(`Icon "${kebabCaseIconName}" not found, use camel case name "${CamelCaseIconName}"`);
    }
    return (_jsx(TheIcon, { className: clsx([
            icon({
                color,
                size,
                spinning: spinning || kebabCaseIconName === 'loading',
            }),
            className,
            preventInteraction,
        ]), "data-testid": testId, width: 24, height: 24 }));
};
