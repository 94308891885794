import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { textBodyStyles } from './text-body.css.js';
import { assignThemeVars } from '../text.utils.js';
import { textTheme } from '../text.theme.css.js';
export const TextBody = ({ align, children, className, color, display, fontFamily = 'sans', size = 'medium', spacing, tag = 'p', testId, theme, transform, truncate, weight = 'regular', }) => {
    const TextTag = `${tag}`;
    return (_jsx(TextTag, { className: clsx([
            textBodyStyles({
                align,
                color,
                display,
                fontFamily,
                size,
                spacing,
                transform,
                truncate,
                weight,
            }),
            className,
        ]), "data-testid": testId, style: assignThemeVars(textTheme, theme), children: children }));
};
